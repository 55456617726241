import React, { useRef, useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { 
  Box, 
  Container, 
  Paper, 
  Typography, 
  Switch, 
  FormControlLabel,
  Link,
  IconButton,
  Slider 
} from "@mui/material";
import {
  PlayArrow,
  Pause,
  SkipNext,
  SkipPrevious,
  MusicNote
} from "@mui/icons-material";
import axios from "axios";

interface AudioDetails {
  title: string;
  artist?: string;
  plays: number;
}

interface RandomAudio {
  vid: string;
  title: string;
  artist?: string;
  plays: number;
}

const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const useAudioPlayer = (
    audioRef: React.RefObject<HTMLAudioElement>,
    autoPlay: boolean,
    onAudioEnd: () => void
  ) => {
  const [isPlaying, setIsPlaying] = useState(true); // Default to true for autoplay
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const handleError = (e: Event) => {
        console.error("Audio error:", e);
        setIsPlaying(false);
      };
  
      const handleEnded = () => {
        setIsPlaying(false);
        onAudioEnd();
      };

      const handleTimeUpdate = () => {
        setCurrentTime(audio.currentTime);
      };
  
      const handleLoadedMetadata = () => {
        setDuration(audio.duration);
        if (autoPlay) {
          audio.play().catch(error => {
            console.warn("Autoplay prevented:", error);
            setIsPlaying(false);
          });
        }
      };

      const handlePlay = () => setIsPlaying(true);
      const handlePause = () => setIsPlaying(false);

      audio.addEventListener("error", handleError);
      audio.addEventListener("ended", handleEnded);
      audio.addEventListener("timeupdate", handleTimeUpdate);
      audio.addEventListener("loadedmetadata", handleLoadedMetadata);
      audio.addEventListener("play", handlePlay);
      audio.addEventListener("pause", handlePause);

    return () => {
      audio.removeEventListener("error", handleError);
      audio.removeEventListener("ended", handleEnded);
      audio.removeEventListener("timeupdate", handleTimeUpdate);
      audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
      audio.removeEventListener("play", handlePlay);
      audio.removeEventListener("pause", handlePause);
    };
  }, [audioRef, autoPlay, onAudioEnd]);

  return {
    isPlaying,
    setIsPlaying,
    duration,
    currentTime,
    setCurrentTime
  };
};

const MusicPlayer: React.FC = () => {
  const { vid } = useParams<{ vid: string }>();
  const navigate = useNavigate();
  const audioRef = useRef<HTMLAudioElement>(null);
  const [audioDetails, setAudioDetails] = useState<AudioDetails | null>(null);
  const [autoPlay, setAutoPlay] = useState<boolean>(() => 
    localStorage.getItem('autoPlay') === 'true'
  );
  const [nextTracks, setNextTracks] = useState<RandomAudio[]>([]);
  const [artworkLoading, setArtworkLoading] = useState(true);
  const [artworkError, setArtworkError] = useState(false);
  const [thumbnailBlob, setThumbnailBlob] = useState<string | null>(null);

  // Modify handleAudioEnd to use preloaded track
  const handleAudioEnd = useCallback(() => {
    if (autoPlay && nextTracks.length > 0) {
      const nextTrack = nextTracks[0];
      const remainingTracks = nextTracks.slice(1);
      setNextTracks(remainingTracks);
      navigate(`/music/${nextTrack.vid}`);
    }
}, [autoPlay, nextTracks, navigate]);

  // Modify the audio element to handle transitions better
  const handleCanPlay = () => {
    if (audioRef.current && isPlaying) {
      audioRef.current.play().catch(error => {
        console.warn("Playback was prevented:", error);
        setIsPlaying(false);
      });
    }
  };

  const {
    isPlaying,
    setIsPlaying,
    duration,
    currentTime,
    setCurrentTime  
  } = useAudioPlayer(audioRef, autoPlay, handleAudioEnd);

  const fetchRandomTracks = async () => {
    try {
      const response = await axios.get(`/api/videos/random?currentVid=${vid}&limit=1`);
      const tracks = response.data.videos;
      setNextTracks(tracks);
    } catch (error) {
      console.error("Error fetching random tracks:", error);
    }
  };

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
    }
  };

  const handleTimeChange = (event: Event, newValue: number | number[]) => {
    const newTime = newValue as number;
    if (audioRef.current) {
      audioRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const handleNextTrack = () => {
    if (nextTracks.length > 0) {
      handleAudioEnd();
    }
  };

  // Setup MediaSession API
  useEffect(() => {
    if ('mediaSession' in navigator) {

      navigator.mediaSession.setActionHandler('play', () => {
        audioRef.current?.play();
      });
      navigator.mediaSession.setActionHandler('pause', () => {
        audioRef.current?.pause();
      });
      navigator.mediaSession.setActionHandler('nexttrack', handleNextTrack);
      navigator.mediaSession.setActionHandler('previoustrack', () => {
        navigate(-1);
      });
    }
  }, [vid, audioDetails, navigate]);

  // Load audio details and setup
  // Fetch thumbnail and audio details
  useEffect(() => {
    setArtworkLoading(true);
    setArtworkError(false);

    // Fetch both thumbnail and audio details simultaneously
    Promise.all([
      // Fetch thumbnail
      fetch(`/api/thumbnail/${vid}`)
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob)),
      
      // Fetch audio details
      axios.get(`/api/stats/${vid}`)
    ])
    .then(([thumbnailUrl, audioResponse]) => {
      setThumbnailBlob(thumbnailUrl);
      setAudioDetails(audioResponse.data);
      
      // Setup MediaSession API
      if ('mediaSession' in navigator) {
        navigator.mediaSession.metadata = new MediaMetadata({
          title: audioResponse.data?.title || 'Unknown Title',
          artist: audioResponse.data?.artist || 'Unknown Artist',
          artwork: [{ src: thumbnailUrl, sizes: '300x300', type: 'image/jpeg' }]
        });
      }
    })
    .catch(error => {
      console.error("Error fetching data:", error);
      setArtworkError(true);
    })
    .finally(() => {
      setArtworkLoading(false);
    });
    // Cleanup function to revoke the blob URL
    if (autoPlay) {
      fetchRandomTracks();
    }
    return () => {
      if (thumbnailBlob) {
        URL.revokeObjectURL(thumbnailBlob);
      }
    };
  }, [vid, autoPlay]);

  return (
    <Container maxWidth="sm" sx={{ mt: 1, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          {artworkError || !thumbnailBlob ? (
            <Box
              sx={{
                width: 300,
                height: 300,
                borderRadius: 1,
                mb: 1,
                bgcolor: 'grey.300',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <MusicNote sx={{ fontSize: 100, color: 'grey.500' }} />
            </Box>
          ) : (
            <Box
              component="img"
              src={thumbnailBlob}
              sx={{
                width: 300,
                height: 300,
                borderRadius: 1,
                mb: 1,
                objectFit: 'contain',
                display: artworkLoading ? 'none' : 'block'
              }}
              onLoad={() => {
                setArtworkLoading(false);
                setArtworkError(false);
              }}             
              onClick={() => {
                navigate(`/video/${vid}`);
              }}
              onError={() => {
                setArtworkLoading(false);
                setArtworkError(true);
              }}
              alt="Album Art"
            />
          )}

          <Typography variant="h6" gutterBottom>
            {audioDetails?.title}
          </Typography>

          <Box sx={{ width: '100%', mb: 2 }}>
            <Slider
              value={currentTime}
              max={duration}
              onChange={handleTimeChange}
              aria-label="time-indicator"
            />
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body2">{formatTime(currentTime)}</Typography>
              <Typography variant="body2">{formatTime(duration)}</Typography>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" gap={2}>
            <IconButton onClick={() => navigate(-1)}>
              <SkipPrevious />
            </IconButton>
            <IconButton onClick={handlePlayPause} size="large">
              {isPlaying ? <Pause /> : <PlayArrow />}
            </IconButton>
            <IconButton onClick={handleNextTrack}>
              <SkipNext />
            </IconButton>
          </Box>

          <audio
            ref={audioRef}
            src={`/api/a/${vid}`}
            playsInline
            autoPlay
            preload="auto"
            onCanPlay={handleCanPlay}
            />

          <Box mt={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={autoPlay}
                  onChange={(e) => {
                    setAutoPlay(e.target.checked);
                    localStorage.setItem('autoPlay', String(e.target.checked));
                    if (e.target.checked) {
                      fetchRandomTracks();
                    }
                  }}
                />
              }
              label="Autoplay"
            />
          </Box>

          {autoPlay && nextTracks.length > 0 && (
            <Box mt={2}>
              <Typography variant="subtitle2" color="textSecondary">
                Next up:{' '}
                <Link
                  component="button"
                  onClick={() => navigate(`/music/${nextTracks[0].vid}`)}
                  sx={{
                    color: 'primary.main',
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline'
                    }
                  }}
                >
                  {nextTracks[0].title}
                </Link>
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default MusicPlayer;