import React from 'react';
import { AppBar, Toolbar, Typography, useTheme, useMediaQuery, IconButton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';

const Header: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <AppBar position="static" sx={{ height: '48px' }}>
      <Toolbar variant={"dense"}>
        {isMobile ? (
          <IconButton
            color="inherit"
            component={RouterLink}
            to="/"
            edge="start"
            sx={{ mr: 2 }}
          >
            <VideoLibraryIcon />
          </IconButton>
        ) : (
          <Typography 
            variant="subtitle1" 
            component={RouterLink} 
            to="/" 
            sx={{ 
              textDecoration: 'none', 
              color: 'inherit',
              flexGrow: 1,
              '&:hover': {
                textDecoration: 'underline',
              }
            }}
          >
          （¯﹃¯）
          </Typography>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;