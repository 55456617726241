import React, { useRef, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { 
  Box, 
  Container, 
  Paper, 
  Typography, 
  Switch, 
  FormControlLabel,
  Link ,
  IconButton
} from "@mui/material";
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import axios from "axios";

interface VideoDetails {
  title: string;
  views: number;
  streamedData: number;
}

interface RandomVideo {
  vid: string;
  title: string;
  views: number;
}

const useVideoPlayer = (
  videoRef: React.RefObject<HTMLVideoElement>,
  autoPlay: boolean,
  onVideoEnd: () => void
) => {
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleError = (e: Event) => {
      console.error("Video error:", e);
    };

    const handleEnded = () => {
      if (autoPlay) {
        onVideoEnd();
      }
    };

    video.addEventListener("error", handleError);
    video.addEventListener("ended", handleEnded);

    // Monkey patch to prevent Safari errors
    const originalPlay = video.play;
    video.play = function() {
      return originalPlay.apply(this).catch(error => {
        console.warn("Playback was prevented:", error);
      });
    };

    return () => {
      video.removeEventListener("error", handleError);
      video.removeEventListener("ended", handleEnded);
      video.play = originalPlay;
    };
  }, [videoRef, autoPlay, onVideoEnd]);
};

const VideoPlayer: React.FC = () => {
  const { vid } = useParams<{ vid: string }>();
  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoDetails, setVideoDetails] = useState<VideoDetails | null>(null);
  // Initialize from localStorage
  const [autoPlay, setAutoPlay] = useState<boolean>(() => 
    localStorage.getItem('autoPlay') === 'true'
  );
  const [nextVideos, setNextVideos] = useState<RandomVideo[]>([]);

  const fetchRandomVideos = async () => {
    try {
      const response = await axios.get(`/api/videos/random?currentVid=${vid}&limit=1`);
      setNextVideos(response.data.videos);
    } catch (error) {
      console.error("Error fetching random videos:", error);
    }
  };

  const handleVideoEnd = () => {
    if (autoPlay && nextVideos.length > 0) {
      const nextVideo = nextVideos[0];
      const remainingVideos = nextVideos.slice(1);
      setNextVideos(remainingVideos);
      navigate(`/video/${nextVideo.vid}`);
    }
  };

  useVideoPlayer(videoRef, autoPlay, handleVideoEnd);

  useEffect(() => {
    axios
      .get(`/api/stats/${vid}`)
      .then((response) => setVideoDetails(response.data))
      .catch((error) => console.error("Error fetching video details:", error));

    if (autoPlay) {
      fetchRandomVideos();
    }

    const handleVisibilityChange = () => {
      if (document.hidden && videoRef.current && !videoRef.current.paused) {
        if (
          'pictureInPictureEnabled' in document &&
          document.pictureInPictureEnabled &&
          !document.pictureInPictureElement
        ) {
          videoRef.current.requestPictureInPicture().catch((error) => {
            console.warn("Failed to enter Picture-in-Picture mode:", error);
          });
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [vid, autoPlay]);

  // Fetch new random videos when queue is low
  useEffect(() => {
    if (autoPlay && nextVideos.length < 2) {
      fetchRandomVideos();
    }
  }, [nextVideos.length, autoPlay]);

  // Update localStorage when toggle changes
  const handleAutoPlayToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setAutoPlay(newValue);
    localStorage.setItem('autoPlay', String(newValue));
    if (newValue) {
      fetchRandomVideos();
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <video
        ref={videoRef}
        src={`/api/v/${vid}`}
        style={{
          width: "100%",
          maxHeight: "70vh",
          objectFit: "contain",
        }}
        autoPlay
        controls
        playsInline
      />
      <Paper elevation={3} sx={{ p: 2 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5" gutterBottom>
            {videoDetails?.title}
          </Typography>
          <IconButton onClick={() => navigate(`/music/${vid}`)}>
            <MusicNoteIcon />
          </IconButton>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="body1">{videoDetails?.views} views</Typography>
          </Box>
          <FormControlLabel
            control={
              <Switch
                checked={autoPlay}
                onChange={handleAutoPlayToggle}
                color="primary"
              />
            }
            label="Autoplay"
          />
        </Box>
        {autoPlay && nextVideos.length > 0 && (
          <Box mt={2}>
            <Typography variant="subtitle2" color="textSecondary">
              Next up:{' '}
              <Link
                component="a"
                href={`/video/${nextVideos[0].vid}`}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/video/${nextVideos[0].vid}`);
                }}
                sx={{
                  color: 'primary.main',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }
                }}
              >
                {nextVideos[0].title}
              </Link>
            </Typography>
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default VideoPlayer;