import React, { useState, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import Header from './components/Header';
import VideoUploader from './components/VideoUploader';
import VideoGrid from './components/VideoGrid';
import VideoPlayer from './components/VideoPlayer';
import MusicPlayer from './components/MusicPlayer';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const App: React.FC = () => {
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const handleVideoListChange = useCallback(() => {
    setRefreshTrigger(prev => prev + 1);
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Router>
          <Header />
          <Routes>
            <Route path="/" element={
              <>
                <VideoUploader onVideoListChange={handleVideoListChange}/>
                <VideoGrid refreshTrigger={refreshTrigger} />
              </>
            } />
            <Route path="/video/:vid" element={<VideoPlayer />} />
            <Route path="/music/:vid" element={<MusicPlayer />} />
          </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;