import React, { useEffect, useState, useCallback, useRef } from 'react';
import axios from 'axios';
import { Box, Card, CardContent, Typography, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { Menu, MenuItem, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface Video {
  vid: string;
  title: string;
  thumbnailFilename: String;
  youtubeId: String;
  fileName: string;
  views: number;
}

interface VideoGridProps {
  refreshTrigger: number;
}

const VideoGrid: React.FC<VideoGridProps> = ({ refreshTrigger }) => {
  const [videos, setVideos] = useState<Video[]>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const observer = useRef<IntersectionObserver | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedVid, setSelectedVid] = useState<string | null>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, vid: string) => {
    event.preventDefault(); // Prevent default Link behavior
    setAnchorEl(event.currentTarget);
    setSelectedVid(vid);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedVid(null);
  };

  const handleOptionSelect = (route: string) => {
    if (selectedVid) {
      window.location.href = `/${route}/${selectedVid}`;
    }
    handleMenuClose();
  };

  const fetchVideos = useCallback(async (reset: boolean = false) => {
    if (isLoading || (!hasMore && !reset)) return;
    setIsLoading(true);
    try {
      const response = await axios.get(`/api/videos?page=${reset ? 1 : page}&limit=10`);
      const newVideos = response.data.videos;
      setVideos(prevVideos => reset ? newVideos : [...prevVideos, ...newVideos]);
      setPage(prevPage => reset ? 2 : prevPage + 1);
      setHasMore(newVideos.length > 0 && (reset ? 1 : page) < response.data.totalPages);
    } catch (error) {
      console.error('Error fetching videos:', error);
    } finally {
      setIsLoading(false);
    }
  }, [page, isLoading, hasMore]);

  useEffect(() => {
    fetchVideos(true); // Reset and fetch first page when refreshTrigger changes
  }, [refreshTrigger]);

  const lastVideoElementRef = useCallback((node: HTMLDivElement | null) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        fetchVideos();
      }
    });
    if (node) observer.current.observe(node);
  }, [fetchVideos, hasMore]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box display="grid" gridTemplateColumns="repeat(auto-fill, minmax(250px, 1fr))" gap={2}>
        {videos.map((video, index) => (
          <Card 
            key={video.vid}
            ref={index === videos.length - 1 ? lastVideoElementRef : null}
            sx={{ 
              display: 'flex', 
              flexDirection: 'column',
              height: '100%',
              bgcolor: 'background.paper',
            }}
          >
            <Box
              component={Link}
              to={`/music/${video.vid}`}
              sx={{
                position: 'relative',
                paddingTop: '56.25%',
                overflow: 'hidden',
              }}
              // onClick={(e) => handleMenuClick(e, video.vid)}
            >
              <Box
                component="img"
                src={`/api/thumbnail/${video.vid}`}
                alt={video.title}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                }}
                onClick={(e) => handleMenuClick(e, video.vid)}
              >
                <MoreVertIcon />
              </IconButton>
            </Box>
            <CardContent sx={{ flexGrow: 1, py: 1 }}>
              <Typography variant="subtitle2" component="div" noWrap>
                {video.title}
              </Typography>
              {/* <Box display="flex" justifyContent="space-between" alignItems="center" mt={0.5}>
                <Typography variant="caption" color="text.secondary">
                  {video.views} views
                </Typography>
              </Box> */}
            </CardContent>
          </Card>
        ))}
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleOptionSelect('music')}>
          Play as Music
        </MenuItem>
        <MenuItem onClick={() => handleOptionSelect('video')}>
          Play as Video
        </MenuItem>
      </Menu>
      {isLoading && <Typography align="center">Loading...</Typography>}
      {!hasMore && <Typography align="center">No more videos</Typography>}
    </Container>
  );
};

export default VideoGrid;