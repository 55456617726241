import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { LinearProgress, Typography, Paper, Button, TextField, Box, CircularProgress, Snackbar, Alert, useTheme, useMediaQuery } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SendIcon from '@mui/icons-material/Send';

interface VideoUploaderProps {
  onVideoListChange: () => void;
}

const VideoUploader: React.FC<VideoUploaderProps> = ({ onVideoListChange }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadSpeed, setUploadSpeed] = useState(0);
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const uploadFile = (formData: FormData) => {
    const startTime = Date.now();
    let lastLoaded = 0;
    setUploadProgress(0);
    setUploadSpeed(0);
    axios.post(`/api/upload`, formData, {
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
          const currentTime = Date.now();
          const elapsedTime = (currentTime - startTime) / 1000; // in seconds
          const loadedBytes = progressEvent.loaded - lastLoaded;
          const speed = loadedBytes / elapsedTime; // bytes per second
          setUploadSpeed(speed / 1024 / 1024); // Convert to MB/s
          lastLoaded = progressEvent.loaded;
        }
      },
    }).then((response) => {
      console.log('Upload successful:', response.data);
      setSuccessMessage('Video uploaded successfully!');
      onVideoListChange(); // Refresh video list
    }).catch((error) => {
      console.error('Upload failed:', error);
      setSuccessMessage('Upload failed. Please try again.');
    });
  };

  const handleYoutubeUpload = async () => {
    if (!youtubeUrl) return;
    setIsLoading(true);
    try {
      const response = await axios.post(`/api/download`, { url: youtubeUrl });
      console.log('YouTube download response:', response.data);
      
      if (response.data.exist) {
        // If video already exists, navigate to its page
        navigate(`/music/${response.data.videoId}`);
        setSuccessMessage('Video already exists!');
      } else {
        // If new download, show success message
        setSuccessMessage('YouTube video downloaded successfully!');
        navigate(`/music/${response.data.videoId}`);
        onVideoListChange(); // Refresh video list
      }
    } catch (error) {
      console.error('YouTube download failed:', error);
      setSuccessMessage('Download failed. Please try again.');
    } finally {
      setIsLoading(false);
      setYoutubeUrl('');
    }
  };

  return (
    <Paper sx={{ padding: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Button
          variant="contained"
          component="label"
          sx={{ mr: 2, minWidth: isMobile ? '40px' : '100px' }}
        >
          {isMobile ? <CloudUploadIcon /> : 'Select'}
          <input
            type="file"
            hidden
            onChange={(e) => {
              if (e.target.files && e.target.files[0]) {
                const formData = new FormData();
                formData.append('video', e.target.files[0]);
                uploadFile(formData);
              }
            }}
          />
        </Button>
        <TextField
          variant="outlined"
          size="small"
          value={youtubeUrl}
          onChange={(e) => setYoutubeUrl(e.target.value)}
          sx={{ mr: 2, flexGrow: 1 }}
          placeholder='Youtube Url'
        />
        <Button
          variant="contained"
          onClick={handleYoutubeUpload}
          disabled={isLoading || !youtubeUrl}
          sx={{ minWidth: isMobile ? '40px' : '100px' }}
        >
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            isMobile ? <SendIcon /> : 'Submit'
          )}
        </Button>
      </Box>
      {uploadProgress > 0 && (
        <Box sx={{ mt: 1 }}>
          <LinearProgress variant="determinate" value={uploadProgress} sx={{ mb: 1 }} />
          <Typography>Upload Progress: {uploadProgress}%</Typography>
          <Typography>Upload Speed: {uploadSpeed.toFixed(2)} MB/s</Typography>
        </Box>
      )}
      
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
      >
        <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default VideoUploader;