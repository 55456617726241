import React from 'react';
import ReactDOM from "react-dom/client";
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import App from './App';
import reportWebVitals from "./reportWebVitals";

const theme = createTheme();
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
);
reportWebVitals();